var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BlockTitle", {
        staticClass: "msg-title",
        attrs: { title: "弹性设置", "is-bold": true },
      }),
      _c(
        "a-row",
        [
          _c(
            "a-radio-group",
            {
              on: { change: _vm.flexTypeChange },
              model: {
                value: _vm.form.shiftFlexType,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "shiftFlexType", $$v)
                },
                expression: "form.shiftFlexType",
              },
            },
            _vm._l(_vm.flexTypeList, function (item) {
              return _c(
                "a-radio",
                { key: item.value, attrs: { value: item.value } },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: +_vm.form.shiftFlexType,
                  expression: "+form.shiftFlexType",
                },
              ],
              staticClass: "flex-box",
            },
            [
              +_vm.form.shiftFlexType === 1
                ? _c(
                    "div",
                    { staticClass: "flex-setting" },
                    [
                      _vm._v(" 晚到"),
                      _c("CbNumber", {
                        staticClass: "number-input",
                        attrs: { min: 1, max: 480 },
                        model: {
                          value: _vm.form.allowLateInMinutes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "allowLateInMinutes", $$v)
                          },
                          expression: "form.allowLateInMinutes",
                        },
                      }),
                      _vm._v("分钟内不计迟到，早走"),
                      _c("CbNumber", {
                        staticClass: "number-input",
                        attrs: { min: 1, max: 480 },
                        model: {
                          value: _vm.form.allowEarlyOutMinutes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "allowEarlyOutMinutes", $$v)
                          },
                          expression: "form.allowEarlyOutMinutes",
                        },
                      }),
                      _vm._v("分钟内不计早退 "),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: +_vm.form.shiftFlexType === 2,
                      expression: "+form.shiftFlexType === 2",
                    },
                  ],
                  staticClass: "flex-setting",
                },
                [
                  _vm._v(" 最多早到早走 "),
                  _c("CbNumber", {
                    staticClass: "number-input",
                    attrs: { min: 5, max: 240 },
                    on: { change: _vm.change },
                    model: {
                      value: _vm.form.flexInMaxMinutes,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "flexInMaxMinutes", $$v)
                      },
                      expression: "form.flexInMaxMinutes",
                    },
                  }),
                  _vm._v(" 分钟，最多晚到晚走 "),
                  _c("CbNumber", {
                    staticClass: "number-input",
                    attrs: { min: 5, max: 240 },
                    on: { change: _vm.change },
                    model: {
                      value: _vm.form.flexOutMaxMinutes,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "flexOutMaxMinutes", $$v)
                      },
                      expression: "form.flexOutMaxMinutes",
                    },
                  }),
                  _vm._v("分钟 "),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: +_vm.form.shiftFlexType === 3,
                      expression: "+form.shiftFlexType === 3",
                    },
                  ],
                  staticClass: "flex-setting",
                },
                [
                  _vm._v(" 有"),
                  _c(
                    "a-select",
                    {
                      attrs: { "allow-clear": "" },
                      on: { change: _vm.change },
                      model: {
                        value: _vm.form.flexTimes,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "flexTimes", $$v)
                        },
                        expression: "form.flexTimes",
                      },
                    },
                    _vm._l(_vm.flexNumList, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  ),
                  _vm._v("个弹性上班时段 "),
                  _c("CbNumber", {
                    staticClass: "number-input",
                    attrs: { min: 5, max: 240 },
                    on: { change: _vm.change },
                    model: {
                      value: _vm.form.flexTimesStep,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "flexTimesStep", $$v)
                      },
                      expression: "form.flexTimesStep",
                    },
                  }),
                  _vm._v("分钟 "),
                ],
                1
              ),
              _vm.describeList.length
                ? _c(
                    "div",
                    { staticClass: "time-display" },
                    _vm._l(_vm.describeList, function (content, i) {
                      return _c("ul", { key: i }, [
                        _c("li", [_vm._v(_vm._s(content))]),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }